
import { defineComponent } from 'vue';

export default defineComponent({
                                 name: 'User',
                                 data() {
                                   return {
                                     model: {
                                       name: '',
                                       email: '',
                                       password: '',
                                       status: 1
                                     },
                                     error: false,
                                     errorMessage: "",
                                     loading: false,
                                     showInput: false
                                   }
                                 },
                                 methods: {
                                   saveUser() {
                                     if (!this.loading) {
                                       if (this.model.password.length > 7) {
                                         this.loading = true;
                                         const formData = {
                                           name: this.model.name,
                                           email: this.model.email,
                                           password: this.model.password,
                                           status: 1
                                         };
                                         this.$store.dispatch('saveUser', formData).then(() => {
                                           this.loading = false;
                                           this.$router.push('/users')
                                         }).catch((error) => {
                                           this.loading = false;
                                           this.error = true;
                                           this.errorMessage = error.response.data.error.message;
                                         })
                                       } else {
                                         this.loading = false;
                                         this.error = true;
                                         this.errorMessage = "Das Passwort muss mindestens 8 Zeichen haben";
                                       }
                                     }
                                   },
                                   switchPasswordView() {
                                     this.showInput = !this.showInput;
                                   }
                                 }
                               });
